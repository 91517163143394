<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import Translation from "@/i18n/translate";
import { userLocationStore } from "@/modules/user-location";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/modules/user-module";
import { useCartStore } from "@/modules/cart-module";
import { UiSnackbar } from "@packages/component-library";
import { useSystemStore } from "@/store/system.store";
import { useCookies } from "@vueuse/integrations/useCookies";
import MarketingNotification from "@/components/MarketingNotification.vue";

const cookies = useCookies();

const locationStore = userLocationStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const systemCity = computed(() => locationStore.city);
const user = computed(() => userStore.user);

const route = useRoute();
const router = useRouter();

const systemStore = useSystemStore();
const notification = computed(() => systemStore.notification);
const showMarketingNotification = ref(false);

onBeforeMount(async () => {
  await router.isReady();

  const queries = structuredClone(route.query);

  if (queries.city) {
    localStorage.setItem("userCity", queries.city);
    delete queries.city;
  }

  if (queries.token && queries.refresh) {
    cookies.set("ksUserToken", queries.token, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: "/",
      domain: import.meta.env.VITE_HOST,
      sameSite: "lax",
    });
    cookies.set("ksUserRefresh", queries.refresh, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: "/",
      domain: import.meta.env.VITE_HOST,
      sameSite: "lax",
    });
    delete queries.token;
    delete queries.refresh;
  }

  Translation.initUserLocale();

  await router.replace({ query: queries });

  await locationStore.loadUserCityInformation(
    parseInt(localStorage.userCity) ? localStorage.userCity : 0
  );

  if (cookies.get("ksUserToken")) {
    await userStore.loadUserInformation().then(() => {
      if (!user.value?.marketing_message_showed_at) {
        showMarketingNotification.value = true;
      }
    });
    await cartStore.loadCarts();
  }
});
</script>

<template>
  <Transition name="notification">
    <UiSnackbar
      v-if="notification"
      :type="notification.type"
      :text="notification.text"
      :title="notification.title"
      class="notification"
      @close="systemStore.hideNotification()"
    />
  </Transition>

  <MarketingNotification
    v-if="showMarketingNotification"
    @close="showMarketingNotification = false"
  />

  <RouterView v-if="systemCity" />
</template>

<style lang="scss" scoped>
.notification {
  position: fixed;
  top: 176px !important;

  @media (max-width: 1239px) {
    top: 132px !important;
    right: 24px !important;
  }

  @media (max-width: 767px) {
    right: 16px !important;
  }
}

.notification-enter-active {
  transition: all 0.3s ease-out;
}

.notification-leave-active {
  transition: all 0.8s ease-out;
}

.notification-enter-from,
.notification-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
</style>
